import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { ContentSubHeading, ContentHeading } from "../components/styles"
import paris from "../assets/paris.jpeg"
import mexico from "../assets/mexico.jpeg"
import newyork from "../assets/newyork.jpeg"
import shanghai from "../assets/shanghai.jpeg"
import RegionCard from "./RegionCard"

export default ({ pageContext: { countries } }) => {
  const NA = ["US", "CA"]
  const LATAM = ["MX"]
  const APAC = ["AU", "CN", "HK", "JP", "NZ", "SK"]
  const EMEA = [
    "AT",
    "BE",
    "CZ",
    "DK",
    "FR",
    "DE",
    "IE",
    "IT",
    "NL",
    "PL",
    "SE",
    "ES",
    "CH",
    "GB",
    "IS",
  ]

  const numCountries = countries.length

  return (
    <Layout
      title={`Pricelist Market Coverage | NA, EMEA, APAC, LATAM`}
      keywords={`Serving ${numCountries} markets across NA, EMEA, APAC, and
    LATAM`}
    >
      <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mt-20 mx-auto max-w-6xl text-lg">
          <h1>
            <ContentSubHeading>
              Serving {countries.length} markets across NA, EMEA, APAC, and
              LATAM
            </ContentSubHeading>
            <ContentHeading>Pricelist Market Coverage</ContentHeading>
          </h1>

          <p className="mx-auto pb-2 pt-8 max-w-4xl text-gray-600">
            Pricelist simplifies product pricing for{" "}
            <span className="text-gray-900">{numCountries} countries</span>{" "}
            around the world and its coverage is growing every week.
          </p>

          <p className="mx-auto pb-2 pt-8 max-w-4xl text-gray-600">
            With Pricelist you gain access to local price components, insights,
            and analysis at scale. From exchange rates optimization to
            competitor price updates, Pricelist allows you to manage your
            go-to-market process with ease.
          </p>

          <div className="mt-16 p-1">
            <RegionCard
              image={paris}
              title="Europe, Middle East, and Africa (EMEA)"
              markets={EMEA}
              countries={countries}
              gradient={"from-indigo-800 to-blue-600"}
            />
            <RegionCard
              image={shanghai}
              title="Asia-Pacific (APAC)"
              inverted={true}
              markets={APAC}
              countries={countries}
              gradient={"from-gray-100 to-gray-200"}
            />
            <RegionCard
              image={mexico}
              title="Latin America (LATAM)"
              markets={LATAM}
              countries={countries}
              gradient={"from-red-800 to-yellow-600"}
            />
            <RegionCard
              image={newyork}
              title="North America (NA)"
              inverted
              markets={NA}
              countries={countries}
              gradient={"from-blue-600 to-blue-800"}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
