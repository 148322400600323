import React from "react"
import Flags from "country-flag-icons/react/1x1"

const flag = {
  AU: <Flags.AU className="h-4 rounded-full" />,
  AT: <Flags.AT className="h-4 rounded-full" />,
  BE: <Flags.BE className="h-4 rounded-full" />,
  CA: <Flags.CA className="h-4 rounded-full" />,
  CN: <Flags.CN className="h-4 rounded-full" />,
  CZ: <Flags.CZ className="h-4 rounded-full" />,
  DK: <Flags.DK className="h-4 rounded-full" />,
  FI: <Flags.FI className="h-4 rounded-full" />,
  FR: <Flags.FR className="h-4 rounded-full" />,
  DE: <Flags.DE className="h-4 rounded-full" />,
  HK: <Flags.HK className="h-4 rounded-full" />,
  IS: <Flags.IS className="h-4 rounded-full" />,
  IE: <Flags.IE className="h-4 rounded-full" />,
  IT: <Flags.IT className="h-4 rounded-full" />,
  JP: <Flags.JP className="h-4 rounded-full" />,
  MX: <Flags.MX className="h-4 rounded-full" />,
  NL: <Flags.NL className="h-4 rounded-full" />,
  NZ: <Flags.NZ className="h-4 rounded-full" />,
  NO: <Flags.NO className="h-4 rounded-full" />,
  PL: <Flags.PL className="h-4 rounded-full" />,
  SK: <Flags.KR className="h-4 rounded-full" />,
  ES: <Flags.ES className="h-4 rounded-full" />,
  SE: <Flags.SE className="h-4 rounded-full" />,
  CH: <Flags.CH className="h-4 rounded-full" />,
  GB: <Flags.GB className="h-4 rounded-full" />,
  US: <Flags.US className="h-4 rounded-full" />,
}

const flagLarge = {
  AU: <Flags.AU className="h-8 rounded-full" />,
  AT: <Flags.AT className="h-8 rounded-full" />,
  BE: <Flags.BE className="h-8 rounded-full" />,
  CA: <Flags.CA className="h-8 rounded-full" />,
  CN: <Flags.CN className="h-8 rounded-full" />,
  CZ: <Flags.CZ className="h-8 rounded-full" />,
  DK: <Flags.DK className="h-8 rounded-full" />,
  FI: <Flags.FI className="h-8 rounded-full" />,
  FR: <Flags.FR className="h-8 rounded-full" />,
  DE: <Flags.DE className="h-8 rounded-full" />,
  HK: <Flags.HK className="h-8 rounded-full" />,
  IS: <Flags.IS className="h-8 rounded-full" />,
  IE: <Flags.IE className="h-8 rounded-full" />,
  IT: <Flags.IT className="h-8 rounded-full" />,
  JP: <Flags.JP className="h-8 rounded-full" />,
  MX: <Flags.MX className="h-8 rounded-full" />,
  NL: <Flags.NL className="h-8 rounded-full" />,
  NZ: <Flags.NZ className="h-8 rounded-full" />,
  NO: <Flags.NO className="h-8 rounded-full" />,
  PL: <Flags.PL className="h-8 rounded-full" />,
  SK: <Flags.KR className="h-8 rounded-full" />,
  ES: <Flags.ES className="h-8 rounded-full" />,
  SE: <Flags.SE className="h-8 rounded-full" />,
  CH: <Flags.CH className="h-8 rounded-full" />,
  GB: <Flags.GB className="h-8 rounded-full" />,
  US: <Flags.US className="h-8 rounded-full" />,
}

export { flag, flagLarge }
