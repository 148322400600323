import React from "react"
import { Link } from "gatsby"
import { useRef } from "react"
import useOnScreen from "./hooks/useOnScreen"
import { flag } from "./flags"
import tw from "twin.macro"
const slug = require("slug")

const H2 = tw.h2`pt-4 pb-2 text-3xl font-black bg-clip-text text-transparent bg-gradient-to-r to-indigo-400 from-indigo-800`

const RegionCard = ({
  image,
  title,
  countries,
  markets,
  gradient,
  inverted = false,
}) => {
  const CountryLink = ({ name, countryCode }) => {
    return (
      <>
        {countryCode !== "US" ? (
          <Link
            to={`/markets/${slug(name)}`}
            className="group py-2 text-gray-500 hover:text-gray-800 font-medium border-b border-gray-100 hover:border-gray-800 transform duration-300"
          >
            <div className="flex items-center text-sm">
              <div className="mr-4">{flag[countryCode]}</div>
              <span className="group-hover:font-bold">{name}</span>
            </div>
          </Link>
        ) : (
          <div className="group py-2 text-gray-500 font-medium border-gray-100">
            <div className="flex items-center text-sm">
              <div className="mr-4">{flag[countryCode]}</div>
              <span>{name}</span>
            </div>
          </div>
        )}
      </>
    )
  }

  const RegionContent = () => (
    <div className="w-full sm:w-1/2">
      <H2 className="pb-2 pt-4 text-transparent text-2xl font-black bg-gradient-to-r bg-clip-text from-indigo-800 to-indigo-400">
        {title}
      </H2>
      <div className="grid gap-4 grid-cols-2 mt-8">
        {countries
          .filter(({ countryCode }) => markets.includes(countryCode))
          .map(({ name, countryCode }) => (
            <CountryLink name={name} countryCode={countryCode} />
          ))}
      </div>
    </div>
  )

  return (
    <div
      className={`flex flex-col items-center mb-32 ${
        inverted ? "sm:flex-row-reverse" : "sm:flex-row"
      }`}
    >
      <RegionCardGraphic
        image={image}
        gradient={gradient}
        inverted={inverted}
      />
      <RegionContent
        title={title}
        countries={countries}
        markets={markets}
        inverted={inverted}
      />
    </div>
  )
}

const RegionCardGraphic = ({ image, gradient, inverted }) => {
  const ref = useRef()
  const onScreen = useOnScreen(ref, "0px")

  return (
    <div
      className={`-p-2 bg-gradient-to-t rounded-2xl ease-in-out transform duration-500 rotate-6 w-full sm:w-1/2 ${gradient} mb-4 sm:mb-0 
      ${inverted ? "sm:ml-16" : "sm:mr-16"}
      ${onScreen ? "sm:scale-105" : "sm:scale-90"}`}
      ref={ref}
    >
      <img src={image} className="rounded-2xl shadow-xl transform -rotate-6" />
    </div>
  )
}

export default RegionCard
